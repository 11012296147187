@import "./../../../../styles/variables.scss";

.postStack-mainHeader {
  background-color: $white;
  border-radius: 8px;
  margin-bottom: 7px;
  margin-top: 14px;
  padding: 6px 0 6px 6px;
  width: 100%;
}

.postStack-mainContainer {
  // background-color: $bg-web;
  // display: flex;
  // flex-direction: column;
  // width: 100%;

  max-width: 1280px;
  margin: 0 auto;
  padding: 32px;
  box-sizing: content-box;
}
.postStack-container {
  margin-left: 79px;
  margin-right: 79px;
  min-height: calc(100vh - 200px);
}
.postStack-headContainer {
  display: flex;
  flex-direction: column;
  padding-top: 33px;
  width: 49%;
  h1 {
    font-size: $font-title;
  }
}

.postStack-buttonContainer {
  display: flex;
  padding-top: 52px;
  column-gap: 35px;
}
.postStack-buttonContainer > * {
  margin-left: 7px;
}

.postStack-icon {
  margin-right: 10px;
}

.postStack-iconButton {
  justify-content: center;
}

.postStack-footerMainContainer {
  position: sticky;
  bottom: 10px;
  margin-top: 20px;
  background-color: $white;
  padding: 20px;
  button {
    max-width: 100%;
  }
}
