@import "./../../../styles/variables.scss";

.autocomplete-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    color: #424242;
    font-family: "DM Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.216px;
  }
  input {
    border-radius: 12px !important;
    border: 1px solid #ececec !important;
    background: #fff !important;
    box-shadow: 0px -1px 0px 0px rgba(66, 66, 66, 0.1) inset,
      0px 1px 3px 0px rgba(66, 66, 66, 0.1) !important;
  }
  input:focus {
    outline: none !important;
    border-color: transparent !important;
    box-shadow: 0px 0px 0px 3px
      color-mix(in srgb, var(--theme-secondary), transparent 50%) !important;
  }
  input:not(:placeholder-shown),
  input:hover {
    border-color: var(--theme-tertiary) !important;
    border-color: color-mix(
      in srgb,
      var(--theme-secondary),
      transparent 50%
    ) !important;
  }
}

.location-search-input:hover {
  border: solid 2px $border-hover;
}
.location-search-input {
  font-family: $font-family-getlife !important;
  font-weight: 400;
  height: 48px;
  border-radius: 8px;
  border: solid 2px $border;
  font-size: 1rem;
  padding-left: 10px;
  transition: border 300ms;
  transition: box-shadow 300ms;
  width: auto !important;
}

.autocomplete-dropdown-container {
  margin-top: 2px;
  border-color: $border;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.autocomplete-dropdown-container:empty {
  border: none;
}

.autocomplete-dropdown-container * {
  font-family: $font-family-getlife !important;
  color: $label;
  width: calc(100% - 32px);
  padding: 10px 0px;
  border-radius: 8px;
  margin: 16px;
}

.location-search-input > *:hover {
  cursor: "pointer";
  background-color: $input-hover;
}
